import { FC } from "react";
import { Transaction } from "../../../context/context";
import {
  BalanceTableContainer,
  Table,
  TableRow,
  TableRowHead,
  TableHead,
  TableData,
  TableBody,
} from "./BalanceTableSection.styles";
import { format } from "date-fns";
import { Markdown } from "../../../../shared/markdown";
import { Row } from "../../../../shared/Layout.styles";
import { balanceConstants } from "../../../../utils/constants/balance";
import { appendZero } from "../../../../utils/helper/appendZero";
import isEmpty from "lodash/isEmpty";

export interface IBalanceTable {
  transactions: Transaction[] | null;
  balance: number | null;
}
export const BalanceTable: FC<IBalanceTable> = ({ transactions, balance }) => {
  const { balanceTableHeading } = balanceConstants;

  const handleNumber = (number: number) =>
    number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");

  return (
    <BalanceTableContainer>
      <Row>
        <Markdown children={balanceTableHeading} />

        <Markdown
          children={`## $${balance ? handleNumber(balance) : "0.00"}`}
          className="balanceValue"
        />
      </Row>
      <Table>
        <TableBody>
          <TableRowHead>
            <TableHead>Date</TableHead>
            <TableHead>Description</TableHead>
            <TableHead>Balance</TableHead>
            <TableHead>Amount</TableHead>
          </TableRowHead>
          {!isEmpty(transactions) ? (
            transactions?.map((item) => {
              return (
                <TableRow key={item.reference}>
                  <TableData>
                    {format(
                      new Date(item.occurred_at),
                      "dd-MM-yyyy hh:mmaaaaa'm'"
                    )}
                  </TableData>
                  <TableData>
                    {item.reference ? item.reference : null}
                  </TableData>
                  <TableData>
                    {item.running_balance_amount
                      ? `$ ${appendZero(item.running_balance_amount)}`
                      : null}
                  </TableData>
                  <TableData>{`$ ${appendZero(item.amount)}`}</TableData>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableData>No transactions found in the last 90 days.</TableData>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </BalanceTableContainer>
  );
};
